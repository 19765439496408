import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {},
  theme_palette: {
    red: "#DF2929",
    gray: "#8E8E93",
    offWhite: "#F5F5F5",
  },
  overrides: {
    MuiPaper: {
      root: {
        outline: "none",
      },
    },
  },

  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#252A34', 
            fontWeight: "bold",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#08D9D6', 
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application !
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "4px",
          padding: "12px 97px",
          fontFamily: "Nunito",
          textTransform: "none",
          fontSize: { xs: "16px", md: "18px" },
          fontWeight: "700",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },
});
