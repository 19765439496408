import { Stack } from "@mui/material";
import PasswordInput from "components/Atoms/Input/PaswordInput";
import PhoneInput from "../PhoneInput/PhoneInput";

export default function PhoneTap({ formik }) {
  return (
    <Stack
      gap={2}
      sx={{
        ".MuiInputLabel-root": { fontSize: { xs: "16px", md: "18px" } },
        ".MuiInput-input": { color: "#000" },
      }}
    >
      <Stack>
        <PhoneInput
          id="phone"
          name="phone"
          value={formik.values.country_code}
          inputValue={formik.values.phone}
          setPhone={formik.handleChange}
          clickEnter={() => void 0}
          placeholder="Ex: 01202035485"
          labels="en"
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          onChange={(e) =>
            formik.setValues({ ...formik?.values, country_code: e.target.value })
          }
        />
      </Stack>
      <PasswordInput
        name="password"
        value={formik?.values.password}
        onChange={formik.handleChange}
        error={formik.errors.password}
      />
    </Stack>
  );
}
