import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EmailTap from "./EmailTap";
import PhoneTap from "./PhoneTab";
import { useDispatch, useSelector } from "react-redux";
import { setTabValue } from "services/modules/Tabs/Actions";

export default function FormTabs({ formik }) {
  const dispatch = useDispatch();
  const value = useSelector((state) => state.tab.tabValue);

  const handleChange = (event, newValue) => {
   dispatch(setTabValue(newValue))
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Phone Number" value="1" />
            <Tab label="Email" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <PhoneTap formik={formik} />
        </TabPanel>
        <TabPanel value="2">
          <EmailTap formik={formik} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
