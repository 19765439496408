import { 
  Container, 
  FormControl, 
  MenuItem, 
  Select, 
  ListSubheader,
  TextField,
  InputAdornment 
} from "@mui/material";
import React, { useState, useMemo } from "react";
import { getCountryCallingCode } from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";
import "./PhoneInput.css";
import SearchIcon from "@mui/icons-material/Search";
import TextFieldInput from "../TextField/TextFieldInput";

function PhoneInput({
  setPhone,
  clickEnter,
  placeholder,
  value,
  labels,
  onCountryCodeChange,
  onChange,
  id,
  name,
  inputValue,
  error,
  helperText,
}) {
  
  const allOptions = getCountries();
  const [searchText, setSearchText] = useState("");
  
  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () => allOptions?.filter((option) => containsText(`${labels[option]}${getCountryCallingCode(option)}`, searchText)),
    [searchText]
  );
 
  return (
    <Container style={{ display: "flex" }} disableGutters>
      <FormControl
        className={
          localStorage.getItem("lang") == "ar"
            ? "code_selectAR"
            : "code_selectEN"
        }
      >
        <Select
          className={"select phone-select"}
          value={value}
          MenuProps={{ autoFocus: false }}
          labelId="search-select-label"
          id="search-select"
          onChange={(e) => onChange(e.target.value)}
          onClose={() => setSearchText("")}
          renderValue={() => localStorage.getItem("lang") === 'en' ? `+${value}` : `${value}+`}
        >
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions.map((country) => (
            <MenuItem key={country} value={getCountryCallingCode(country)}>
              {labels[country]} +{getCountryCallingCode(country)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextFieldInput
        error={error}
        helperText={helperText}
        name={name}
        id={id}
        value={inputValue}
        placeholder={placeholder}
        onchange={setPhone}
        className={"mb-20px"}
        onKeyDown={(e) => {
          if (e.key === "Enter") clickEnter();
        }}
      />
    </Container>
  );
}

export default PhoneInput;
