import { Stack, TextField, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import PasswordInput from "components/Atoms/Input/PaswordInput";

export default function EmailTap({ formik }) {
  const { red } = useThemePalette();

  return (
    <Stack
      gap={2}
      sx={{
        ".MuiInputLabel-root": { fontSize: { xs: "16px", md: "18px" } },
        ".MuiInput-input": { color: "#000" },
      }}
    >
      <Stack>
        <TextField
          label="Email"
          variant="outlined"
          sx={{
            '& > :not(style)': { width: '100%' },
          }}
          name="email"
          onChange={formik.handleChange}
          value={formik?.values["email"]}
        />
        <Typography sx={{ color: red }}>{formik.errors["email"]}</Typography>
      </Stack>
      <PasswordInput
        name="password"
        value={formik?.values.password}
        onChange={formik.handleChange}
        error={formik.errors.password}
      />
    </Stack>
  );
}
