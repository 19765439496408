import { icons } from "assets/AssetHelper";
import Loader from "components/Atoms/Loader/Loader";
import AlertModal from "components/Atoms/Modal/AlertModal";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteAccountRequest } from "services/modules/Auth";
import { ToggleAlertModal } from "services/modules/modals/Actions";
import DeleteAccountTemplate from "templates/deleteAccount/DeleteAccount.Template";
import * as Yup from "yup";

const emailValidation = Yup.object().shape({
  email: Yup.string().required().email("Invalid Email").label("Email"),
  password: Yup.string().required().label("Password"),
});

const phoneValidation = Yup.object().shape({
  country_code: Yup.string().required('Country code is required'),
  phone: Yup.string().required('Phone number is required'),
  password: Yup.string().required().label("Password"),
});
export default function DeleteAccountPage() {
  const dispatch = useDispatch();
  const load = useSelector((state) => state?.auth?.isLoading);
  const tab = useSelector((state) => state.tab.tabValue);

  const handleDeleteAccount = (values) => {
    let { country_code, phone, email, password } = values;
    country_code = `+${country_code}`
    const action = () => {
      dispatch(
        ToggleAlertModal({
          isOpen: true,
          icon: icons?.Success,
          message: "Your account has been successfully deleted.",
        })
      );
      formik?.resetForm();
    };
    const failAction = (error) => {
      console.log(error);
      const errorType = error?.response?.data?.message;
      if (errorType === "Invalid credentials") {
        dispatch(
          ToggleAlertModal({
            isOpen: true,
            icon: icons?.Exclamationmark,
            message: (
              <>
                Oops! There seems to be a mismatch. <br />
                Check your email and password again.
              </>
            ),
          })
        );
      } else if (errorType === "Account Not Found") {
        dispatch(
          ToggleAlertModal({
            isOpen: true,
            icon: icons?.NotFound,
            message: (
              <>
                That account seems to be missing! <br />
                Double-check the email and try again.
              </>
            ),
          })
        );
      }
    };
    const requestData = {
      body: Number(tab) === 1
          ? { authenticate_by:"phone_number", country_code, phone_number: phone, password }
          : { authenticate_by:"email", email, password }
      ,
      action,
      failAction,
    };
    dispatch(DeleteAccountRequest(requestData));
  };

  const formik = useFormik({
    initialValues: { country_code: "20", phone: "", email: "", password: "" },
    validationSchema: Number(tab) === 1 ? phoneValidation : emailValidation,
    onSubmit: async (values) => {
      handleDeleteAccount(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Loader open={Boolean(load?.loading)} />
      <AlertModal />
      <DeleteAccountTemplate formik={formik} />
    </>
  );
}
