import Logo from "./images/logo.png";
import DeleteAccount from "./icons/DeleteAccount.png";
import Exclamationmark from "./icons/Exclamationmark.svg";
import Success from "./icons/Success.svg";
import NotFound from "./icons/NotFound.svg";

export const dummy = {};

export const images = {
  Logo,
};

export const icons = {
  DeleteAccount,
  Exclamationmark,
  Success,
  NotFound,
};
