import { Routes, Route, Navigate } from "react-router-dom";
import DeleteAccountPage from "pages/deleteAccount/DeleteAccount.Page";

const AppRoute = () => {
  // this state is for update breadcrumb data for each page
  return (
    <Routes>
      {/* admin layout */}
      <Route path="/" element={<Navigate to="/delete-account" />}>
        <Route element={<p>Not found</p>} />
      </Route>
      <Route path="/delete-account" element={<DeleteAccountPage />} />
    </Routes >
  );
};

export default AppRoute;
