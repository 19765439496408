import { useState } from "react";
import { Stack, Typography, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useThemePalette } from "common/hooks/theme_palette";

export default function PasswordInput({ value, onChange, error,name }) {
  const { red } = useThemePalette();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  return (
    <Stack sx={{ position: "relative" }}>
      <TextField
        name={name}
        label="Password"
        variant="outlined"
        type={isShowPassword ? "text" : "password"}
        sx={{  '& > :not(style)': { width: '100%' } }}
        value={value}
        onChange={onChange}
      />
      <Typography sx={{ color: red }}>{error}</Typography>

      <Stack onClick={toggleShowPassword} sx={{ position: "absolute", right: "15px", top: "35%" }}>
        {isShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </Stack>
    </Stack>
  );
}
