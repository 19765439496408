import React from "react";
import AlertProvider from "./components/Atoms/Alerts/ToastifyAlert";
import "./App.css";
import AppRoute from "./common/Route";
import { ThemeProvider } from "@mui/material";
import { theme } from "./common/styles/theme";
import { store } from "./services/store";
import { Provider as ReduxProvider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import setAuthToken from "./services/modules/utils/handel_api";
import { I18nextProvider } from "react-i18next";
import i18n from "common/i18n";

const App = () => {
  const custom_theme = theme;
  const token = localStorage.getItem("token");
  setAuthToken(token);

  return (
    <I18nextProvider i18n={i18n}>
      <ReduxProvider store={store}>
        <ThemeProvider theme={custom_theme}>
          <AlertProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AppRoute />
            </LocalizationProvider>
          </AlertProvider>
        </ThemeProvider>
      </ReduxProvider>
    </I18nextProvider>
  );
};
export default App;
