import Types from "./Types";

const initialState = {
  tabValue: '1',
};

export const tabReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_TAB_VALUE:
      return {
        ...state,
        tabValue: action.payload,
      };
    default:
      return state;
  }
};
